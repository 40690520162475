<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <div>
          <a class="btn bg" @click="my_geo();">내위치 보기</a>
        </div>
        <div class="map_part_wrapper mgT10">
          <div class="map_search_wrapper" :class="[!show_search ? 'disactive' : '']">
            <div class="map_search_inner">
              <div class="map_search">
                <input type="text" v-model="map_search_keyword" placeholder=" 주소로 검색하세요." />
                <a @click="map_search()" class="btn">검색</a>
              </div>
              <div class="map_search_results">
                <div class="result" v-for="m in map_search_result" :key="m">
                  <div @click="map_set_center(m.lat, m.lng)">
                    <div class="title">{{ m.poi_name }}</div>
                    <div class="address">{{ m.poi_address }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="toggler" @click="toggleSearch()">〓</div>
          </div>
          <div class="map_blocker" :class="[map_config.loading ? 'active' : '']">
            <p>loading...</p>
          </div>

          <div id="map_part"></div>
        </div>

        <div v-if="now_pick_poi != null" id="now_pick_part" style="width:500px;">
          <span class="h_title">선택한 POI</span>
          <div class="table_type2">
            <table>
              <colgroup>
                <col width="10%" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">POI번호</th>
                  <td>{{ now_pick_poi.no }}</td>
                </tr>
                <tr>
                  <th scope="row">분류</th>
                  <td>{{ now_pick_poi.poi_type }}</td>
                </tr>
                <tr>
                  <th scope="row">이름</th>
                  <td>{{ now_pick_poi.poi_name }}</td>
                </tr>
                <tr>
                  <th scope="row">주소</th>
                  <td>{{ now_pick_poi.poi_value }}</td>
                </tr>
                <tr>
                  <th scope="row">관리</th>
                  <td>
                    <a v-if="now_pick_poi.ischecked" @click="pick(now_pick_poi.no)" class="btn size1">찜하기</a>
                    <a v-else class="btn size1">찜됨</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="btn_box mgT20">
          <a class="btn" v-bind:class="{ 'bg4': current_tab == 1, 'bg': current_tab != 1 }" @click="toggle_tab(1)">POI
            목록(찜X)</a>&nbsp;
          <a class="btn" v-bind:class="{ 'bg4': current_tab == 2, 'bg': current_tab != 2 }" @click="toggle_tab(2)">POI
            목록(찜O)</a>&nbsp;
        </div>

        <div class="fx-5 mgT20">
          <div class="list_box" v-bind:class="{ 'tab_on': current_tab == 1, 'tab_off': current_tab != 1 }">
            <h2 class="h_title">POI 목록(찜X)</h2>
            <div style="width:120px;">
              <select class="selectbox" @change="region_select($event);">
                <option value="" selected disabled>지역 선택</option>
                <option value="">전체</option>
                <option v-for="region in region_list" :key="region" :value="region.check">{{ region.desc }}</option>
              </select>
            </div>
            <div class="search_wrapper mgT10">
              <input type="text" class="inputbox" @input="doSearch" placeholder="검색어를 입력하세요." />
            </div>
            <!-- table_type -->
            <div class="table_type1 mgT20">
              <table>
                <colgroup>
                  <col width="4%" />
                  <col width="6%" />
                  <col width="6%" />
                  <col width="8%" />
                  <col />
                  <col />
                  <col width="10%" />
                </colgroup>
                <thead>
                  <th scope="col">선택</th>
                  <th scope="col">번호</th>
                  <th scope="col">POI번호</th>
                  <th scope="col">분류</th>
                  <th scope="col">이름</th>
                  <th scope="col">주소</th>
                  <th scope="col">관리</th>
                </thead>
                <tbody>
                  <!-- <tr v-if="poi_list_my.length == 0">
                  <td colspan="6">읽어오는중...</td>
                </tr> -->
                  <tr v-for="(p, index) in poi_list_x" :key="p.no">
                    <td>
                      <div v-if="p.ischecked === true">
                        <input type="checkbox" name="confirm" v-bind:value="p.no" />
                      </div>
                    </td>
                    <td>{{ poi_numbering_x(index) }}</td>
                    <td>{{ p.no }}</td>
                    <td>{{ p.poi_type }}</td>
                    <td style="text-align:left;">{{ p.poi_name }}</td>
                    <td style="text-align:left;">
                      <span class="address" @click="map_set_center(p.lat, p.lng)">
                        {{ p.poi_address }}
                      </span>
                    </td>
                    <td>
                      <div v-if="p.ischecked === true">
                        <a @click="pick(p.no)" class="btn size1">찜하기</a>
                      </div>
                      <div v-else>
                        <div class="btn size1">찜됨</div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- //table_type -->
            <div class="list_bot mgT20">
              <div class="fx" style="gap: 5px">
                <a @click="select_all()" class="btn">전체선택</a>
                <a @click="select_delete()" class="btn">선택삭제</a>
              </div>

              <div class="page_box">
                page : {{ page_x }} / {{ page_total_x }}
                &nbsp;
                <a @click="page_x_change('prev')" class="btn size1">이전</a>
                &nbsp;
                <a @click="page_x_change('next')" class="btn size1">다음</a>
              </div>

              <div>
                <a @click="pick_select()" class="btn bg1">선택항목 찜하기</a>
              </div>
            </div>
          </div>
          <div class="list_box" v-bind:class="{ 'tab_on': current_tab == 2, 'tab_off': current_tab != 2 }">
            <div>
              <h2 class="h_title">POI 목록(찜O)</h2>
              <div class="table_type2" style="width:350px;">
                <table>
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col v-if="my_cno == 5" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th style="text-align:center" scope="col">등록대기</th>
                      <th style="text-align:center" scope="col">등록확정</th>
                      <th style="text-align:center" scope="col">승인</th>
                      <th style="text-align:center" scope="col">반려</th>
                      <th v-if="my_cno == 5" style="text-align:center" scope="col">음식점</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="text-align:center">{{ count_check.status1 }}개</td>
                      <td style="text-align:center">{{ count_check.status2 }}개</td>
                      <td style="text-align:center">{{ count_check.status3 }}개</td>
                      <td style="text-align:center">{{ count_check.status_1 }}개</td>
                      <td v-if="my_cno == 5" style="text-align:center">{{ count_check.food }}개</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="search_wrapper">
              <input type="text" class="inputbox" @input="doSearch_o" placeholder="검색어를 입력하세요." />
            </div>
            <!-- table_type -->
            <div class="table_type1 mgT20">
              <table>
                <colgroup>
                  <col width="6%" />
                  <col width="6%" />
                  <col width="8%" />
                  <col />
                  <col />
                  <col width="8%" />
                  <col width="13%" />
                  <col width="13%" />
                </colgroup>
                <thead>
                  <th scope="col">번호</th>
                  <th scope="col">POI번호</th>
                  <th scope="col">분류</th>
                  <th scope="col">이름</th>
                  <th scope="col">주소</th>
                  <th scope="col">상태</th>
                  <th scope="col">잔여시간</th>
                  <th scope="col">관리</th>
                </thead>
                <tbody>
                  <tr v-if="poi_list_my.length == 0">
                    <td colspan="8">찜한 POI가 없습니다.</td>
                  </tr>
                  <tr v-for="(p, index) in poi_list_my" :key="p.no">
                    <td>{{ poi_numbering_o(index) }}</td>
                    <td>{{ p.poi_no }}</td>
                    <td>{{ p.poi_type }}</td>
                    <td style="text-align:left;">{{ p.poi_name }}</td>
                    <td style="text-align:left;">
                      <span class="address" @click="map_set_center(p.lat, p.lng)">
                        {{ p.poi_address }}
                      </span>
                    </td>
                    <td>
                      <span v-if="p.state == -2" class="co_state3">취소됨</span>
                      <span v-else-if="p.status == 1 && p.is_expire == 1" class="co_state3">기간만료</span>
                      <span v-else-if="p.status == 1" class="co_state">등록대기</span>
                      <span v-else-if="p.status == 2" class="co_state1">등록확정</span>
                      <span v-else-if="p.status == 3" class="co_state2">승인</span>
                      <span v-else-if="p.status == -1" class="co_state3">반려</span>
                    </td>
                    <td>
                      {{ get_time(p.pick_expire) }}
                      <a class="btn size1" @click="time_extend(p.no, p.time_extend)">
                        <span v-if="p.time_extend < time_extend_limit">연장하기</span>
                        <span v-else>연장횟수만료</span>
                      </a>
                    </td>
                    <td>
                      <span v-if="p.is_expire == 1 && (p.status == 1 || p.status == -1)">기간만료</span>
                      <template v-else-if="(p.status == 1 || p.status == -1) && p.state == 1">
                        <router-link :to="{ path: '/img_create/' + p.no }" class="btn size1">이미지등록</router-link>
                        <a class="btn size1" @click="pick_cancel(p.no)">찜 취소</a>
                      </template>
                      <router-link v-else :to="{ path: '/img_view/' + p.no }" class="btn size1">확인</router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- //table_type -->
            <div class="list_bot mgT20">
              <div class="page_box">
                page : {{ page_o }} / {{ page_total_o }}
                &nbsp;
                <a @click="page_o_change('prev')" class="btn size1">이전</a>
                &nbsp;
                <a @click="page_o_change('next')" class="btn size1">다음</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //contents -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import common from "../common/common.js";
import config from "../common/config.js";
import Header from "../components/Header.vue";

export default {
  name: "Img_list",
  data() {
    return {
      is_mobile: false, // 모바일 여부, false면 PC
      poi_list: [], // POI 목록(찜O) 전체
      poi_list_my: [], // POI 목록(찜X) 전체
      my_mno: 0,
      my_cno: 0,
      now_pick_poi: null,
      current_tab: 1,
      visible: false,
      address: "",
      center: [37.5642135, 127.0016985],
      time_extend_limit: 1,

      show_search: true,

      region_selected: "",
      region_list: [
        { desc: "서울", check: "서울" },
        { desc: "인천", check: "인천" },
        { desc: "경기", check: "경기" },
        { desc: "제주", check: "제주" },
        { desc: "강원", check: "강원" },
        { desc: "경북", check: "경북,경상북도" },
        { desc: "대구", check: "대구" },
        { desc: "울산", check: "울산" },
        { desc: "경남", check: "경남,경상남도" },
        { desc: "부산", check: "부산" },
        { desc: "충북", check: "충북,충청북도" },
        { desc: "충남", check: "충북,충청남도,세종" },
        { desc: "대전", check: "대전" },
        { desc: "전북", check: "전북,전라북도" },
        { desc: "광주", check: "광주" },
        { desc: "전남", check: "전남,전라남도" },
        { desc: "기타", check: "없음" },
      ],

      poi_num_x: 1, // 목록 수 초기화
      page_x: 1, // 현재 페이지
      page_total_x: 1, // 총 페이지
      limit_x: 15, // 페이지당 최대 개수
      poi_list_x: [], // 페이지에 표현되는 POI 목록
      item_total_x: 0,
      search: "",
      search_queue: "",
      search_now: "",

      poi_num_o: 1,
      page_o: 1,
      page_total_o: 1,
      limit_o: 15,
      poi_list_o: [],
      item_total_o: 0,
      search_o: "",
      search_queue_o: "",
      search_now_o: "",
      count_check: {},

      map: null,
      map_config: {
        map: null,
        clusterer: null,
        markers: [],
        swLatLng: 0,
        neLatLng: 0,
        opened_info: [],
        level: 5,
        loading: false,
        my_marker: null,
      },
      map_search_result: [],
      map_search_keyword: "",
    };
  },
  components: {
    Header,
  },
  methods: {
    my_geo() { // 내 위치 가져와서 맵 가운데 이동
      if ('geolocation' in navigator) {
        /* 위치정보 사용 가능 */
        navigator.geolocation.getCurrentPosition((pos) => {
          // console.log(pos);
          var latitude = pos.coords.latitude;
          var longitude = pos.coords.longitude;
          // alert("현재 위치는 : " + latitude + ", " + longitude);
          this.map_set_center(latitude, longitude); // 지도 중심 이동

          if (this.map_config.my_marker != null) { // 기존 내 마커가 있으면 초기화
            this.map_config.my_marker.setMap(null);
          }
          var imageSize = new kakao.maps.Size(24, 35);
          var imageSrc = "https://tsafer2.gabia.io/design/com/markerStar_pick.png";
          var position = new kakao.maps.LatLng(latitude, longitude);
          var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize);
          var marker = new kakao.maps.Marker({
            map: window.mapconf_map, // 마커를 표시할 지도
            position: position, // 마커를 표시할 위치
            title: "현재 내 위치", // 마커의 타이틀, 마커에 마우스를 올리면 타이틀이 표시됩니다
            image: markerImage, // 마커 이미지
          });
          this.map_config.my_marker = marker;
        }, (error) => {
          console.log(error);
        });
      } else {
        /* 위치정보 사용 불가능 */
        console.log("위치정보가 없는듯!?");
      }
    },
    pick_cancel(ino) { // 찜 취소
      if (confirm("해당 POI를 찜 취소하시겠습니까? 이 작업은 되돌릴 수 없습니다.")) {
        axios({
          url: config.apiurl + "?part=image&mode=pick_cancel",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            ino: ino,
          },
        }).then((response) => {
          let d = response.data;
          // console.log(d);
          if (d.result == 1) {
            alert("찜 취소되었습니다.");
            this.poi_load_my();
          } else {
            alert("찜 취소 도중 문제가 발생했습니다. 관리자에게 문의해주세요.");
          }
        }).catch(function (error) {
          alert("오류가 발생했습니다...(1)");
          console.log(error);
        });
      }
    },
    time_extend(ino, time_extend) { // 찜 잔여시간 연장
      if (time_extend < this.time_extend_limit) {
        if (confirm(this.time_extend_limit + "회에 한해 24시간을 연장할 수 있습니다. 연장하시겠습니까?")) {
          axios({
            url: config.apiurl + "?part=image&mode=time_extend",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            method: "POST",
            data: {
              token: this.$store.state.token,
              ino: ino,
            },
          }).then((response) => {
            let d = response.data;
            // console.log(d);
            if (d.result == 1) {
              alert("잔여시간이 24시간 연장되었습니다.");
              this.poi_load_my();
            } else if (d.result == 2) {
              alert("기간이 만료된 찜은 연장할 수 없습니다.");
            } else if (d.result == 3) {
              alert("취소처리 된 찜은 연장할 수 없습니다.");
            } else {
              alert("잔여시간 연장 도중 문제가 발생했습니다. 관리자에게 문의해주세요.");
            }
          }).catch(function (error) {
            alert("오류가 발생했습니다...(2)");
            console.log(error);
          });
        }
      } else {
        alert("최대 " + this.time_extend_limit + "회까지 연장할 수 있습니다.");
      }
    },
    region_select(event) { // 찜X 목록에서 지역 선택
      this.region_selected = event.target.value;
      this.poi_load();
    },
    toggle_tab(no) { // 찜X, 찜O 탭 이동
      this.current_tab = no;
    },
    toggleSearch: function () {
      this.show_search = this.show_search ? false : true;
    },
    doSearch: function (e) {
      this.search = e.target.value;
      clearTimeout(this.search_queue);
      this.search_queue = setTimeout(() => {
        this.search_now = this.search;
        this.page_x = 1;
        this.poi_list = [];
        this.poi_load();
      }, 250);
    },
    doSearch_o: function (e) {
      this.search_o = e.target.value;
      clearTimeout(this.search_queue_o);
      this.search_queue_o = setTimeout(() => {
        this.search_now_o = this.search_o;
        this.page_o = 1;
        this.poi_list_my = [];
        this.poi_load_my();
      }, 250);
    },
    page_x_change(direction) {
      if (direction == "next") {
        this.page_x =
          this.page_x + 1 <= this.page_total_x ? this.page_x + 1 : this.page_x;
        this.poi_load();
      } else if (direction == "prev") {
        this.page_x =
          Number(this.page_x) - Number(1) > 0
            ? Number(this.page_x - 1)
            : Number(this.page_x);
        this.poi_load();
      }
    },
    page_o_change(direction) {
      if (direction == "next") {
        this.page_o =
          this.page_o + 1 <= this.page_total_o ? this.page_o + 1 : this.page_o;
        this.poi_load_my();
      } else if (direction == "prev") {
        this.page_o = this.page_o - 1 > 0 ? this.page_o - 1 : this.page_o;
        this.poi_load_my();
      }
    },
    poi_load() { // 찜되지 않은 poi 목록
      this.poi_list = [];
      let search = "";
      if (this.search !== "") {
        search = this.search;
      }
      return new Promise((resolv) => {
        axios({
          url: config.apiurl + "?part=image&mode=list2",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            page: this.page_x,
            limit: this.limit_x,
            search: search,
            region: this.region_selected,
          },
        }).then((response) => {
          let d = response.data;
          var list = d.list;
          console.log(d);
          this.my_mno = d.my_mno;
          this.poi_list = list;
          this.poi_list_x = list;
          this.poi_num_x = list.length;
          this.item_total_x = d.item_total;
          this.page_total_x = d.page_total;
          resolv();
        }).catch(function (error) {
          alert("오류가 발생했습니다...(3)");
          console.log(error);
        });
      });
    },
    poi_mapload() {
      // 찜되지 않은 poi 목록
      return new Promise((resolv) => {
        let ll_lat = this.map_config.swLatLng.getLat();
        let ll_lng = this.map_config.swLatLng.getLng();
        let ur_lat = this.map_config.neLatLng.getLat();
        let ur_lng = this.map_config.neLatLng.getLng();
        // console.log(this.map_config.swLatLng);
        // console.log(this.map_config.neLatLng);
        axios({
          url: config.apiurl + "?part=image&mode=list4",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            ll_lat: ll_lat.toFixed(6),
            ll_lng: ll_lng.toFixed(6),
            ur_lat: ur_lat.toFixed(6),
            ur_lng: ur_lng.toFixed(6),
          },
        }).then((response) => {
          let d = response.data;
          console.log(d);
          this.map_config.list = d.list;

          resolv();
        }).catch(function (error) {
          alert("오류가 발생했습니다...(4)");
          console.log(error);
        });
      });
    },
    poi_load_my() { // 내가 찜한 poi 목록
      this.poi_list_my = [];
      let search = "";
      if (this.search_o !== "") {
        search = this.search_o;
      }
      axios({
        url: config.apiurl + "?part=image&mode=list3",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          page: this.page_o,
          limit: this.limit_o,
          search: search,
        },
      }).then((response) => {
        let d = response.data;
        console.log(d);
        this.my_mno = d.my_mno;
        this.my_cno = d.my_cno;
        let list = d.list;
        this.poi_list_my = list;
        this.poi_num_o = list.length;
        this.item_total_o = d.item_total;
        this.page_total_o = d.page_total;
        this.count_check = d.count_check;
      }).catch(function (error) {
        alert("오류가 발생했습니다...(5)");
        console.log(error);
      });
    },
    poi_numbering_x(num) {
      // POI 목록(찜X) 번호 세팅
      return this.item_total_x - (this.limit_x * (this.page_x - 1) + num);
    },
    poi_numbering_o(num) {
      // POI 목록(찜O) 번호 세팅
      return this.item_total_o - (this.limit_o * (this.page_o - 1) + num);
    },
    select_all() {
      // 전체선택
      const checkboxes = document.getElementsByName("confirm");

      checkboxes.forEach((checkbox) => {
        if (checkbox.checked) {
          checkbox.checked = false;
        } else {
          checkbox.checked = true;
        }
      });
    },
    select_delete() {
      // 선택삭제
      alert("준비중입니다.");
    },

    pick(poi_no, select = false) {
      // 찜하기
      var check = false;
      if (!select) {
        check = confirm("해당 POI를 찜하시겠습니까?");
      } else {
        check = true;
      }

      if (check) {
        axios({
          url: config.apiurl + "?part=image&mode=pick",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            poi_no: poi_no,
          },
        })
          .then((response) => {
            if(response.data.result == -3){
              alert("이미 찜된 POI입니다.");
            }
            this.poi_load();
            this.poi_load_my();
          })
          .catch(function (error) {
            alert("오류가 발생했습니다...(6)");
            console.log("에러야!");
            console.log(error);
          });
      }
    },
    pick_select() {
      // 선택항목 찜하기
      if (confirm("선택한 POI를 전부 찜하시겠습니까?")) {
        const checkboxes = document.getElementsByName("confirm");
        checkboxes.forEach((checkbox) => {
          if (checkbox.checked) {
            this.pick(checkbox.value, true);
          }
        });
      }
    },
    get_time(expireddate) {
      // 잔여시간
      return common.remained_time(expireddate);
    },
    map_set_center(lat, lng) {
      // 지도 중심좌표 이동 및 depth 1로 확대
      var new_center = new kakao.maps.LatLng(lat, lng);
      window.mapconf_map.setCenter(new_center);
      window.mapconf_map.setLevel(1);
      this.map_calc_bound();
    },
    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async map_calc_bound() {
      // console.log("calc bound");
      this.map_config.loading = true;
      var bounds = window.mapconf_map.getBounds();
      this.map_config.swLatLng = bounds.getSouthWest(); // 남서
      this.map_config.neLatLng = bounds.getNorthEast(); // 북동
      this.map_config.level = window.mapconf_map.getLevel();
      let start = new Date();
      await this.map_clear_markers();
      console.log("마커 지우기 시간 ==> " + (new Date() - start) / 1000);
      window.mapconf_clusterer.clear(); // 클러스터 초기화
      await this.poi_mapload();

      if (this.map_config.level < 5) {
        await this.map_request_marker();
      } else {
        await this.map_request_cluster();
      }
      this.map_config.loading = false;
    },

    map_clear_markers() { // 마커 다 지우기
      return new Promise((resolv) => {
        for (let i = 0; i < this.map_config.markers.length; i++) {
          this.map_config.markers[i].marker.setMap(null);
        }
        this.map_config.markers = [];
        resolv();
      });
    },

    async map_request_cluster() {
      let start = new Date();
      let l = this.map_config.list.length;
      let cmarkers = [];
      for (let i = 0; i < l; i++) {
        let poi = this.map_config.list[i];
        cmarkers.push(
          new kakao.maps.Marker({
            position: new kakao.maps.LatLng(poi.lat, poi.lng),
          })
        );
      }
      window.mapconf_clusterer.addMarkers(cmarkers);
      console.log("마커 클러스터링 시간 ==> " + (new Date() - start) / 1000);
    },
    map_config_markers() {
      return new Promise((resolv) => {
        this.map_config.list.forEach((poi) => {
          var p = new kakao.maps.LatLng(poi.lat, poi.lng);
          var marker = new kakao.maps.Marker({ position: p });
          this.map_config.markers.push({
            poi: poi,
            p: p,
            marker: marker,
          });
        });
        resolv(1)
      });
    },
    async map_request_marker() {
      let start = new Date();
      var imageSrc_0 = "https://kvqa.crowdbank.co.kr/design/com/icon_pin.png";   // 보라색 : 기본(찜X)
      var imageSrc_1 = "https://kvqa.crowdbank.co.kr/design/com/icon_pin_1.png"; // 초록색 : 내가 찜
      var imageSrc_2 = "https://kvqa.crowdbank.co.kr/design/com/icon_pin_2.png"; // 노란색 : 등록하고 검수대기중
      var imageSrc_3 = "https://kvqa.crowdbank.co.kr/design/com/icon_pin_3.png"; // 파란색 : 완료, 검수됨
      var imageSrc_4 = "https://kvqa.crowdbank.co.kr/design/com/icon_pin_4.png"; // 빨간색 : 반려
      var imageSrc_5 = "https://kvqa.crowdbank.co.kr/design/com/icon_pin_5.png"; // 회색   : 다른사람이 찜
      imageSrc_1;imageSrc_2;imageSrc_3;imageSrc_4;

      var imageSize = new kakao.maps.Size(35, 45); // 마커이미지의 크기입니다
      var imageOption = { offset: new kakao.maps.Point(27, 69) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

      await this.map_config_markers();

      this.map_config.markers.forEach((marker) => {
        let imageSrc = imageSrc_0; // 기본
        // console.log(marker)
        let poi = marker.poi; // 해당 POI 정보
        let p = marker.p; // 마커의 위치


        // 상태에 따른 마커 변경
        // if (poi.istate != null && poi.istatus != null) {
        //   if (poi.mno != this.my_mno) { // 다른사람의 poi(회색)
        //     imageSrc = imageSrc_5;
        //   } else { // 내 poi
        //     if (poi.istatus == 1) { imageSrc = imageSrc_1; }       // 찜됨(초록색)
        //     else if (poi.istatus == 2) { imageSrc = imageSrc_2; }  // 등록하고 검수대기중(노란색)
        //     else if (poi.istatus == 3) { imageSrc = imageSrc_3; }  // 완료, 검수됨(파란색)
        //     else if (poi.istatus == -1) { imageSrc = imageSrc_4; } // 반려(빨간색)
        //   }
        // }
        if (!poi.ischecked) {
          imageSrc = imageSrc_5;
        }

        let markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption);

        marker.marker.setImage(markerImage);
        marker.marker.setMap(window.mapconf_map);

        let iwContent = `<div class="pin_info_box">
                          <div class="table_type5">
                                <table>
                                  <colgroup>
                                    <col width="20%">
                                    <col>
                                  </colgroup>
                                  <tbody>
                                    <tr>
                                      <th scope="row">번호</th>
                                      <td>${poi.no}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">분류</th>
                                      <td>${poi.poi_type}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">이름</th>
                                      <td>${poi.poi_name}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">주소</th>
                                      <td>${poi.poi_value}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <!-- //table_type -->
                              <div class="txC mgT20">`;
        if (poi.ischecked) {
          iwContent += `<a onclick="pick(${poi.no})"    data-no='${poi.no}' class="info_pick btn bg2"><img src="/design/com/icon_bookmark.png" alt=""> 찜하기</a>&nbsp;&nbsp;`;
        }
        iwContent += `<a onclick="infoClose(${poi.no})" data-no='${poi.no}' class="info_close btn bg1"><img src="/design/com/icon_bookmark.png" alt=""> 닫기</a>
                        </div>
                    </div>`;
        let iwPosition = p;

        let infowindow = new kakao.maps.InfoWindow({
          position: iwPosition,
          content: iwContent,
        });

        if (!this.is_mobile) { // PC : 인포윈도우 표시
          kakao.maps.event.addListener(marker.marker, "click", () => {
            infowindow.open(window.mapconf_map, marker.marker);
            this.map_config.opened_info[poi.no] = infowindow;
            this.now_marker(poi);
          });
        } else { // Moblie : 인포윈도우 미표시
          kakao.maps.event.addListener(marker.marker, "click", () => {
            this.now_marker(poi);
          });
        }
      });
      console.log("마커 찍기 시간 ==> " + (new Date() - start) / 1000);
    },
    now_marker(poi) { // 맵파트 아래에 지금 보고있는 마커의 poi정보 표시
      this.now_pick_poi = poi;
    },
    initMap() {
      window.mapconf_map = new kakao.maps.Map(
        document.getElementById("map_part"),
        {
          center: new kakao.maps.LatLng(this.center[0], this.center[1]),
          level: this.map_config.level,
          draggable: true,
        }
      );

      window.mapconf_map.setMaxLevel(9);

      window.mapconf_clusterer = new kakao.maps.MarkerClusterer({
        map: window.mapconf_map,
        averageCenter: true,
        minClusterSize: 1,
        minLevel: 4,
      });

      // kakao.maps.event.addListener(
      //   window.mapconf_map,
      //   "tilesloaded",
      //   this.map_calc_bound
      // );
      kakao.maps.event.addListener(
        window.mapconf_map,
        "dragend",
        this.map_calc_bound
      );
      kakao.maps.event.addListener(
        window.mapconf_map,
        "zoom_changed",
        this.map_calc_bound
      );
      // kakao.maps.event.addListener(
      //   window.mapconf_map,
      //   "center_changed",
      //   this.map_calc_bound
      // );
      this.map_calc_bound();
    },
    map_search() {
      axios({
        url: config.apiurl + "?part=image&mode=map_search",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          search: this.map_search_keyword,
        },
      }).then((response) => {
        this.map_search_result = response.data.map_search_result;
      }).catch(function (error) {
        alert("오류가 발생했습니다...(7)");
        console.log("에러야!");
        console.log(error);
      });
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 21);
    this.is_mobile = await common.isMobile(); // PC, Mobile 체크
    await this.poi_load();
    this.poi_load_my();
    const script = document.createElement("script");
    /* global kakao */
    script.onload = () => kakao.maps.load(this.initMap);
    script.src =
      "//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=6d213af40222b5124ab4c81049cb37c4&libraries=services,clusterer";
    document.head.appendChild(script);

    const vm = this;
    window.pick = (no) => {
      vm.pick(no);
    };
    window.infoClose = (no) => {
      vm.map_config.opened_info[no].close();
    };
  },
};
</script>


<style>
.tab_on {
  display: block;
}

.tab_off {
  display: none;
}

.page_box a {
  user-select: none;
}

.map_search_wrapper {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 300px;
  height: calc(100% - 20px);
  box-sizing: border-box;
  border-radius: 14px;
  z-index: 10000;
  background: white;
  box-shadow: 0 4px 8px rgba(32, 33, 36, 0.28);
  transition: 0.3s;
  border-radius: 5px;
  padding: 10px 0;
  transition: 0.3s all ease;
  overflow: hidden;
}

.map_search_wrapper.disactive {
  width: 40px;
  height: 40px;
}

.map_search_wrapper .map_search_inner {
  width: 300px;
  height: 100%;
  transition: 0.3s all ease;
}

.map_search_wrapper.disactive .map_search_inner {
  padding-left: 40px;
}

.map_search_wrapper .toggler {
  position: absolute;
  left: 0;
  bottom: 0;
  border-bottom: 15px solid red;
  border-top: 15px solid transparent;
  border-left: 15px solid red;
  border-right: 15px solid transparent;
  z-index: 20090;
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  color: white;
  transition: 0.2s;
  text-align: center;
}

.map_search_wrapper.disactive .toggler {
  border: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 22px;
  background: red;
}

.map_search {
  height: 30px;
  line-height: 30px;
  padding: 0;
  display: flex;
  justify-content: space-around;
  padding-bottom: 12px;
  border-bottom: 1px #ddd solid;
}

.map_search input {
  padding: 0 0 0 10px;
  margin: 0;
  height: 30px;
  line-height: 30px;
  border: 0;
  vertical-align: top;
  width: 70%;
  box-sizing: border-box;
}

.map_search input:focus {
  outline: none;
}

.map_search .btn {
  height: 30px;
  border-radius: 2px;
  margin-right: 10px;
  line-height: 30px;
  width: 30%;
  text-align: center;
}

.map_search_results {
  padding-top: 0px;
  height: calc(100% - 35px);
  overflow-y: scroll;
}

.map_search_results .result {
  border-bottom: 1px #ddd solid;
  padding: 8px 10px;
  position: relative;
  cursor: pointer;
}

.map_search_results .result .title {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 2px;
}

.map_search_results .result .address {
  font-size: 1em;
  word-break: keep-all;
}

/* .map_search_results .result .title {
  width: calc(100% - 100px);
}

.map_search_results .result .result_pin {
  position: absolute;
  right: 10px;
  z-index: 10010;
  top: 0;
  line-height: 38px;
  display: inline-block;
} */

.map_blocker {
  display: none;
  z-index: 20000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 100px;
  /* background: white; */
  /* box-shadow: 0 4px 8px rgba(32, 33, 36, 0.28); */
  /* transition: 0.3s; */
  border-radius: 5px;
}

.map_blocker.active {
  display: block;
}

.map_blocker p {
  line-height: 100px;
  font-size: 42px;
  text-align: center;
  text-shadow: 0 0px 10px white, 0 0px 20px blue, 0 0px 30px white,
    0 0px 40px blue;
  font-weight: bold;
  color: black;
}
</style>