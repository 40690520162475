<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents">
        <div style="float: right">
          <router-link :to="{ path: '/mypage/' }" class="btn bg2">목록으로</router-link>
        </div>
        <h2 class="h_title">더미이미지 수정 할당</h2>
        <span style="color:blue"> 이미지 작업이 완료된 항목 중 더미이미지가 1장이라도 포함된 작업을 수정합니다.</span>

        <h2 class="h_title2 mgT50">더미이미지 수정 작업자</h2>

        <div class="btn_box mgT10">
          <router-link class="btn bg" :to="{ path: '/img_edit_day_table/' }">일자별 작업량 보기</router-link>&nbsp;
        </div>
        <div class="table_type1 mgT10">
          <table>
            <colgroup>
              <col width="5%" />
              <col width="5%" />
              <col width="8%" />
              <col width="8%" />
              <col width="6%" />
              <col width="33%" />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">선택</th>
                <th scope="col">번호</th>
                <th scope="col">아이디</th>
                <th scope="col">이름</th>
                <th scope="col">소속</th>
                <th scope="col">할당 관리</th>
                <th scope="col">할당량</th>
                <th scope="col">등록중</th>
                <th scope="col">등록완료</th>
                <th scope="col">검수요청</th>
                <th scope="col">검수승인</th>
                <th scope="col">반려</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="5"></td>
                <th>
                  할당가능 : {{ assign_num }} (5장 : {{ assign_num5 }} / 1장 : {{ assign_num1 }})
                  <br>
                  <input type="checkbox" name="assign_cate" id="assign_cate5" :checked="assign_cate == 5"
                    @change="set_assign_cate(5)">
                  <label for="assign_cate5" class="stop-dragging">5장 할당</label>&nbsp;&nbsp;&nbsp;
                  <input type="checkbox" name="assign_cate" id="assign_cate1" :checked="assign_cate == 1"
                    @change="set_assign_cate(1)">
                  <label for="assign_cate1" class="stop-dragging">1장 할당</label>
                </th>
                <th>{{ total_count.assign }}</th>
                <th>{{ total_count.state0 }}</th>
                <th>{{ total_count.state1 }}</th>
                <th>{{ total_count.state2 }}</th>
                <th>{{ total_count.state3 }}</th>
                <th>{{ total_count.state_1 }}</th>
              </tr>
              <tr v-if="member_list.length == 0">
                <td colspan="11">
                  <span>배정된 작업자가 없습니다.</span>
                </td>
              </tr>
              <tr v-for="(m, index) in member_list" :key="m.no">
                <td>
                  <input type="checkbox" />
                </td>
                <td>{{ member_numbering(index) }}</td>
                <td>{{ m.userid }}</td>
                <td>{{ m.username }}</td>
                <td>{{ m.class_name }}</td>
                <td>
                  <select v-if="assign_cate == 5" v-bind:id="'selected_type' + m.no">
                    <option :value="['전체', '전체', assign_num5]" selected>전체({{ assign_num5 }})</option>
                    <option v-for="rt in region_type_list" :key="rt" :value="[rt.poi_type, rt.poi_region, rt.cnt]">
                      {{ rt.poi_type }}/{{ rt.poi_region }}({{ rt.cnt }})</option>
                  </select>
                  &nbsp;
                  <input type="number" v-bind:id="'member' + m.no" />&nbsp;&nbsp;&nbsp;
                  <template v-if="(assigned == 0)">
                    <a @click="assign(m.no)" class="btn size1">할당하기</a>&nbsp;&nbsp;
                    <a @click="assign_cancel(m.no)" class="btn size1 bg3">할당취소</a>
                  </template>
                  <span v-else>할당중입니다...</span>
                </td>
                <td>{{ m.assign_num }}</td>
                <td>{{ m.state0 }}</td>
                <td>{{ m.state1 }}</td>
                <td>{{ m.state2 }}</td>
                <td>{{ m.state3 }}</td>
                <td>{{ m.state_1 }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="5"></th>
                <th>합계</th>
                <th>{{ total_count.assign }}</th>
                <th>{{ total_count.state0 }}</th>
                <th>{{ total_count.state1 }}</th>
                <th>{{ total_count.state2 }}</th>
                <th>{{ total_count.state3 }}</th>
                <th>{{ total_count.state_1 }}</th>
              </tr>
            </tfoot>
          </table>
        </div>
        <!-- //table_type -->

        <div class="page_box mgT10">
          page :
          <input type="text" class="page" v-model="page" @input="paging($event, true)" />
          / {{ page_total }}
          &nbsp;
          <a @click="paging(-1)" class="btn size1">이전</a>
          &nbsp;
          <a @click="paging(1)" class="btn size1">다음</a>
          &nbsp;
          <!-- <span>페이지 이동 후 약 5초 정도의 로딩시간이 있습니다.</span> -->
        </div>
      </div>
      <!-- //contents -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: "ImgEditAssign",
  data: function () {
    return {
      member_list: [],
      assign_num: 0,
      assign_num5: 0, // 5장더미
      assign_num1: 0, // 1장더미(와스코)
      total_assign: 0,

      assign_cate: 5,

      total_count: {},

      worker_nums: 1,
      page: 1,
      page_total: 1,
      limit: 20,

      assigned: 0,
      region_type_list: [],
    };
  },
  components: {
    Header,
  },
  methods: {
    set_assign_cate(num) {
      this.assign_cate = num;
    },
    paging(count, to = false) {
      // 리스트 페이징
      var c = 1;
      if (to) {
        c = Number(count.target.value);
      } else {
        c = this.page + count;
      }

      if (c >= 1 && c <= this.page_total) {
        this.page = c;
      }
      this.member_list_load();
    },
    member_list_load() {
      axios({
        url: config.apiurl + "?part=image&mode=image_edit_assign_list",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          page: this.page,
          limit: this.limit,
        },
      })
        .then((response) => {
          var d = response.data;
          console.log(d);
          this.member_list = d.list;
          this.total_count = d.total_count;
          this.assign_num = d.assign_num;
          this.assign_num5 = d.assign_num5; // 5장더미
          this.assign_num1 = d.assign_num1; // 1장더미(와스코)
          this.worker_nums = d.worker_nums;
          this.page_total = d.page_total;
          this.region_type_list = d.region_type_list;
        })
        .catch(function (error) {
          alert("오류가 발생했습니다...(1)");
          console.log("에러야!");
          console.log(error);
        });
    },
    member_numbering(num) {
      // 작업자 목록 번호 세팅
      if (this.page < 1) {
        return this.worker_nums - num;
      } else if (this.page >= 1 && this.page <= this.page_total) {
        return this.worker_nums - (this.page - 1) * this.limit - num;
      } else {
        return this.worker_nums - (this.page_total - 1) * this.limit - num;
      }
    },
    assign(mno) {
      // 할당하기
      var numid = document.getElementById("member" + mno.toString());
      var num = Number(numid.value);

      var region = ''; var type = '';

      var anum = 0;
      if (this.assign_cate == 1) {
        anum = this.assign_num1;
      } else if (this.assign_cate == 5) {
        var rtid = document.getElementById("selected_type" + mno.toString());
        rtid = rtid.value.split(",");

        type = rtid[0];
        region = rtid[1];
        anum = rtid[2];
      }

      // console.log(type);
      // console.log(region);
      // console.log(anum);

      if (num >= 1 && num <= anum) {
        this.assigned = 1;
        axios({
          url: config.apiurl + "?part=image&mode=image_edit_assign",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            mno: mno,
            num: num,
            assign_cate: this.assign_cate,
            type: type,
            region: region,
          },
        }).then((response) => {
          console.log(response.data);
          alert(num + "개가 할당되었습니다.");
          this.assigned = 0;
          this.member_list_load();
        }).catch(function (error) {
          alert("오류가 발생했습니다...(2)");
          console.log("에러야!");
          console.log(error);
        });
      } else {
        alert("1개부터 " + anum + "개까지 할당할 수 있습니다.");
      }
    },
    assign_cancel(mno) {
      // 할당취소
      if (confirm("정말로 할당된 작업을 반환하시겠습니까? 이 작업은 돌이킬 수 없습니다")) {
        axios({
          url: config.apiurl + "?part=image&mode=image_edit_assign_cancel",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            mno: mno,
          },
        }).then((response) => {
          let d = response.data;
          console.log(d);
          alert(d.cancel_count + "개의 작업물이 반환되었습니다.");
          this.member_list_load();
        }).catch(function (error) {
          alert("오류가 발생했습니다...(3)");
          console.log("에러야!");
          console.log(error);
        });
      }
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 25);
    this.member_list_load();
  },
};
</script>


<style>
.page {
  width: 50px;
  border: 0;
  border-bottom: 1px red solid;
  text-align: center;
  box-sizing: border-box;
}

.page_box * {
  box-sizing: border-box;
}

.page_box {
  line-height: 18px;
}

.page_box a {
  user-select: none;
}

.stop-dragging {
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
</style>