<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <h2 class="h_title">
          더미이미지 수정하기
        </h2>

        <span style="color:blue;">※비고 : 작업량은 '검수승인'된 작업물을 기준으로 집계됩니다.</span>
        <div class="table_type2" style="width:400px;">
          <table>
            <colgroup>
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th style="text-align:center" scope="col">작업량 / 할당량 ( 진행률[%] )</th>
                <th style="text-align:center" scope="col">오늘 검수요청한 이미지</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="text-align:center">{{ work }} / {{ assign }} ({{ work_rate }}%)</td>
                <td style="text-align:center">{{ today_work }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="search_wrapper mgT10">
          <input type="number" class="inputbox" style="width:260px;" v-model="search_poi" placeholder="POI 번호로 검색" />
          <a class="btn bg2" style="float:right;" @click="request_confirm_check()">검수 요청하기</a>
          &nbsp;
          <a @click="doSearch2()" class="btn bg">검색</a>
          &nbsp;&nbsp;
          <select type="selectbox" class="selectbox" style="width:200px;" @change="region_select($event)">
            <option value="" selected disabled>지역 선택</option>
            <option value="">전체</option>
            <option v-for="r in region_list" :key="r" :value="r.value" :selected="page_option.now_region == r.value">{{
                r.value
            }}</option>
          </select>
          &nbsp;
          <select type="selectbox" class="selectbox" style="width:200px;" @change="type_select($event)">
            <option value="" selected disabled>관광타입 선택</option>
            <option value="">전체</option>
            <option v-for="t in type_list" :key="t" :value="t.value" :selected="page_option.now_type == t.value">{{
                t.value
            }}</option>
          </select>
          &nbsp;
          <a v-bind:class="{ 'btn bg4': page_option.confirm_o, 'btn bg': !page_option.confirm_o }"
            @click="toggle_confirm('o')">반려된 POI 보기</a>
          &nbsp;
          <a v-bind:class="{ 'btn bg4': page_option.confirm_x, 'btn bg': !page_option.confirm_x }"
            @click="toggle_confirm('x')">등록완료한 POI 보기</a>
        </div>
        <div class="search_wrapper mgT10">
          <input type="text" class="inputbox" @input="doSearch" placeholder="검색어를 입력하세요." />
        </div>
        <!-- table_type -->
        <div class="table_type1 mgT20">
          <table>
            <colgroup>
              <col width="6%" />
              <col width="6%" />
              <col width="6%" />
              <col />
              <col />
              <col width="8%" />
              <col width="8%" />
              <col width="10%" />
            </colgroup>
            <thead>
              <th scope="col">
                <a @click="select_all()" class="btn bg">
                  <span v-if="select_flag == false">전체선택</span>
                  <span v-else>전체해제</span>
                </a>
              </th>
              <th scope="col">번호</th>
              <th scope="col">POI번호</th>
              <th scope="col">이름</th>
              <th scope="col">주소</th>
              <th scope="col">이미지 업로더</th>
              <th scope="col">상태</th>
              <th scope="col">관리</th>
            </thead>
            <tbody>

              <tr v-for="(c, index) in confirm_list" :key="c.no">
                <td>
                  <input type="checkbox" name="confirm" v-bind:value="[c.no, c.state]">
                </td>
                <td>{{ confirm_numbering(index) }}</td>
                <td>{{ c.poi_no }}</td>
                <td class="txL">{{ c.poi_name }}</td>
                <td class="txL">{{ c.poi_address }}</td>
                <td v-bind:class="{ 'wasco': c.cno == 5 }">{{ c.username }}</td>

                <td>
                  <span v-if="c.state == 0" class="co_state">등록중</span>
                  <span v-if="c.state == 1" class="co_state2">등록완료</span>
                  <span v-if="c.state == 2" class="co_state">검수대기</span>
                  <span v-if="c.state == 3" class="co_state1">검수승인</span>
                  <span v-if="c.state == -1" class="co_state3">반려</span>
                </td>
                <td>
                  <router-link v-if="c.state == 0 || c.state == -1" :to="{ path: '/img_edit_view/' + c.ino }"
                    class="btn size1">수정</router-link>
                  <router-link v-else :to="{ path: '/img_edit_view/' + c.ino }" class="btn size1">보기</router-link>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
        <div class="page_box mgT10">
          page : <input type="text" class="page" v-model="page" @input="paging($event, true)"> / {{ page_total }}
          &nbsp;
          <a @click="paging(-1)" class="btn size1">이전</a>
          &nbsp;
          <a @click="paging(1)" class="btn size1">다음</a>
        </div>
        <!-- //table_type -->
      </div>
      <!-- //contents -->
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: 'ImgEditList',
  data() {
    return {
      region_list: [
        { "value": "서울" }, { "value": "인천" }, { "value": "경기" },
        { "value": "제주" },
        { "value": "강원" }, { "value": "경북" }, { "value": "대구" }, { "value": "울산" }, { "value": "경남" }, { "value": "부산" },
        { "value": "충북" }, { "value": "충남" }, { "value": "대전" }, { "value": "전북" }, { "value": "광주" }, { "value": "전남" },
        { "value": "기타" }
      ],
      type_list: [
        { "value": "관광지" }, { "value": "문화시설" }, { "value": "음식점" }, { "value": "레포츠" }, { "value": "숙박" }, { "value": "쇼핑" }, { "value": "편의오락" },
      ],

      search: "",
      search_now: "",
      search_queue: false,
      search_poi: "",

      confirm_list: [],
      page: 1,
      page_total: 1,
      limit: 10,
      poi_num: 1,
      page_option: {
        confirm_o: false,
        confirm_x: false,
        now_region: "",
        now_type: "",
      },
      work: 0,
      today_work: 0,
      assign: 0,
      work_rate: 0,

      select_flag: false,
    };
  },
  components: {
    Header
  },
  methods: {
    region_select(e) {
      this.page_option.now_region = e.target.value;
      this.edit_list_load();
    },
    type_select(e) {
      this.page_option.now_type = e.target.value;
      this.edit_list_load();
    },
    request_confirm_check() {
      const checkboxes = document.getElementsByName("confirm");
      var check_list = [];
      var is_edit0 = true;
      checkboxes.forEach((checkbox) => {
        if (checkbox.checked) {
          var c = checkbox.value.split(",");
          console.log(c)
          check_list.push(c[0]);

          if (c[1] != 1) {
            is_edit0 = false;
          }
        }
      });

      if (check_list.length == 0) {
        alert("검수를 요청할 poi를 체크해주세요.");
      } else {
        if (!is_edit0) {
          alert("[등록완료]인 작업물만 검수를 요청할 수 있습니다.");
        } else {
          if (confirm("선택한 POI를 검수 요청하시겠습니까?")) {
            this.request_confirm(check_list);
          }
        }
      }
    },
    request_confirm(gnos) {
      axios({
        url: config.apiurl + "?part=image&mode=image_edit_state",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          gnos: gnos,
          state: 2,
        },
      }).then((response) => {
        console.log(response.data)
        alert("검수 요청되었습니다.");
        this.edit_list_load();
      }).catch(function (error) {
        alert("오류가 발생했습니다...(2)");
        console.log(error);
      });
    },
    select_all() {
      var checkboxs = document.getElementsByName('confirm');
      if (!this.select_flag) {
        checkboxs.forEach(checkbox => {
          console.log(checkbox.checked);
          checkbox.checked = true;
        });
        this.select_flag = true;
      } else {
        checkboxs.forEach(checkbox => {
          console.log(checkbox.checked);
          checkbox.checked = false;
        });
        this.select_flag = false;
      }
    },
    toggle_confirm(ox) {
      // this.page_option.confirm_o = false; this.page_option.confirm_x = false;
      if (ox == 'o') {
        if (!(!this.page_option.confirm_o && this.page_option.confirm_x)) {
          this.page_option.confirm_o = !this.page_option.confirm_o;
          this.edit_list_load();
        }
      }
      else if (ox == 'x') {
        if (!(!this.page_option.confirm_x && this.page_option.confirm_o)) {
          this.page_option.confirm_x = !this.page_option.confirm_x;
          this.edit_list_load();
        }
      }
    },
    edit_list_load(poi_no = 0) { // 수정할 리스트 불러오기
      let confirm = "";
      let search = "";
      if (poi_no == 0) {
        if (this.page_option.confirm_o) {
          confirm = 1;
        } else if (this.page_option.confirm_x) {
          confirm = 2;
        }

        this.poi_list = [];
        if (this.search !== "") {
          search = this.search;
        }
      }

      axios({
        url: config.apiurl + "?part=image&mode=image_edit_list",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          page: this.page,
          limit: this.limit,
          confirm: confirm,
          search: search,
          poi_no: poi_no,
          region: this.page_option.now_region,
          type: this.page_option.now_type,
        },
      }).then((response) => {
        let d = response.data;
        console.log(d);
        this.poi_num = d.poi_num;
        this.page_total = d.page_total;
        this.confirm_list = d.confirm_list;
        this.work = d.work;
        this.today_work = d.today_work;
        this.assign = d.assign;
        this.work_rate = d.work_rate;
        common.set_page_info(this, this.$route.name, this.page, search, this.page_option);
      }).catch(function (error) {
        alert("오류가 발생했습니다...(1)");
        console.log("에러야!");
        console.log(error);
      });
    },
    confirm_numbering(num) { // 검수 목록 번호 세팅
      if (this.page < 1) {
        return this.poi_num - num;
      } else if (this.page >= 1 && this.page <= this.page_total) {
        return this.poi_num - ((this.page - 1) * this.limit) - num;
      } else {
        return this.poi_num - ((this.page_total - 1) * this.limit) - num;
      }
    },
    paging(count, to = false) { // 리스트 페이징
      var c = 1;
      if (to) {
        c = Number(count.target.value);
      } else {
        c = this.page + count;
      }

      if (c >= 1 && c <= this.page_total) {
        this.page = c;
      }
      this.edit_list_load();
    },
    doSearch: function (e) {
      this.search = e.target.value;
      clearTimeout(this.search_queue);
      this.search_queue = setTimeout(() => {
        this.search_now = this.search;
        this.page = 1;
        this.edit_list_load();
      }, 250);
    },
    doSearch2() {
      this.edit_list_load(this.search_poi);
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 24);
    await common.set_page(this);
    this.edit_list_load();
  }
};
</script>


<style>
.page {
  width: 50px;
  border: 0;
  border-bottom: 1px red solid;
  ;
  text-align: center;
  box-sizing: border-box;
}

.page_box * {
  box-sizing: border-box;
}

.page_box {
  line-height: 18px;
}

.page_box a {
  user-select: none;
}

.wasco {
  color: rgb(69, 69, 252) !important;
}
</style>