<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <div class="view_top">
          <h2 class="h_title">POI 정보</h2><a href="/img_list" class="btn size1">목록보기</a>
        </div>

        <!-- table_type -->
        <div class="table_type1">
          <table>
            <colgroup>
              <col width="8%">
              <col>
              <col>
              <col>
              <col width="10%">
            </colgroup>
            <thead>
              <th scope="col">번호</th>
              <th scope="col">분류</th>
              <th scope="col">이름</th>
              <th scope="col">주소</th>
              <th scope="col">상태</th>
              <th scope="col">잔여시간</th>
            </thead>
            <tbody>
              <tr>
                <td>{{ poi_info.no }}</td>
                <td>{{ poi_info.poi_type }}</td>
                <td>{{ poi_info.poi_name }}</td>
                <td>{{ poi_info.poi_address }}</td>
                <td>
                  <span v-if="poi_info.status == 1" class="co_state"> 등록대기</span>
                  <span v-if="poi_info.status == 2" class="co_state1">등록확정</span>
                  <span v-if="poi_info.status == 3" class="co_state2">승인</span>
                  <span v-if="poi_info.status == -1" class="co_state3">반려</span>
                </td>
                <td>{{ get_time(poi_info.pick_expire) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- //table_type -->
        <h3 class="mgT50 h_title">업로드 이미지</h3>
        <div class="gd-1">
          <ul v-if="img_list.length == 0">
            등록된 이미지가 없습니다.
          </ul>
          <ul v-else>
            <li v-for="(img, index) in img_list" :key="img.no">
              <p class="image_desc">{{ img_std[index] }}</p>
              <div class="img">
                <img v-if="img.img_url != '' || img.img_url != null" :src="'http://' + img.img_url" alt="">
                <img v-else :src="'http://' + img.endpoint + '/design/com/img_ex0.jpg'" alt="">
              </div>
            </li>
            <li v-for="index in (img_max - img_list.length)" :key="index">
              <p class="image_desc">{{ img_std[img_list.length + index - 1] }}</p>
              <div class="img">
                <img :src="'http://' + endpoint + '/design/com/img_ex0.jpg'" alt="">
              </div>
            </li>
          </ul>
        </div>

        <div v-if="poi_info.status == -1">
          <h3 class="mgT50 h_title">반려사유</h3>
          <div class="box_type1">{{ poi_info.reject_reason }}</div>
        </div>

        <div class="list_bot mgT20" style="justify-content: end; gap: 5px"><a href="/img_list" class="btn bg1">확인</a>
        </div>
      </div>
      <!-- //contents -->
    </div>

  </div>
</template>

<script>
import axios from "axios";
import common from "../common/common.js";
import config from "../common/config.js";
import Header from "../components/Header.vue";

export default {
  name: "Img_view",
  data() {
    return {
      img_max: 5,
      endpoint: "kvqa.crowdbank.co.kr",
      poi_info: {},
      img_list: [],
      img_std: [],
    }
  },
  components: {
    Header
  },
  methods: {
    poi_load() { // POI 정보 불러오기
      axios({
        url: config.apiurl + "?part=image&mode=list3",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          ino: this.$route.params.img_no
        },
      }).then((response) => {
        this.poi_info = response.data.list[0];
        this.img_std = response.data.image_std;
      }).catch(function (error) {
        alert("오류가 발생했습니다...(1)");
        console.log("에러야!");
        console.log(error);
      });
    },
    img_load() { // 이미지 정보 불러오기
      axios({
        url: config.apiurl + "?part=image&mode=image_list",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          ino: this.$route.params.img_no
        },
      }).then((response) => {
        this.img_list = response.data.image_list;
      }).catch(function (error) {
        alert("오류가 발생했습니다...(2)");
        console.log("에러야!");
        console.log(error);
      });
    },
    get_time(expireddate) { // 잔여시간
      return common.remained_time(expireddate);
    }
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 21);
    this.poi_load();
    this.img_load();
  }
};
</script>
