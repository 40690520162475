<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <div class="view_top">
          <h2 class="h_title">POI 정보</h2><a href="/img_edit_confirm_list" class="btn size1">목록보기</a>
        </div>
        <!-- table_type -->
        <div class="table_type1">
          <table>
            <colgroup>
              <col width="8%">
              <col width="8%">
              <col width="8%">
              <col>
              <col>
              <!-- <col width="10%"> -->
            </colgroup>
            <thead>
              <th scope="col">번호</th>
              <th scope="col">분류</th>
              <th scope="col">오류체크 상태</th>
              <th scope="col">이름</th>
              <th scope="col">주소</th>
              <!-- <th scope="col">상태</th>
              <th scope="col">잔여시간</th> -->
            </thead>
            <tbody>
              <tr>
                <td>{{ poi_info.no }}</td>
                <td>{{ poi_info.poi_type }}</td>
                <td>
                  <span v-if="poi_info.is_reject == 1" class="co_state3">오류</span>
                  <span v-else-if="poi_info.is_reject == 0" class="co_state1">정상</span>
                </td>
                <td>{{ poi_info.poi_name }}</td>
                <td>{{ poi_info.poi_address }}</td>
                <!-- <td>
                  <span v-if="poi_info.status == 1" class="co_state"> 등록대기</span>
                  <span v-if="poi_info.status == 2" class="co_state1">등록확정</span>
                  <span v-if="poi_info.status == 3" class="co_state2">검수대기중</span>
                  <span v-if="poi_info.status == -1" class="co_state3">반려</span>
                </td>
                <td>{{ get_time(poi_info.pick_expire) }}</td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <h3 class="mgT50 h_title">이미지 업로드</h3>
        <div class="gd-1">
          <ul>
            <li v-for="(img, index) in img_list" :key="img.no">
              <p class="image_desc">{{ image_std[index] }}</p>
              <div class="img">
                <img v-if="img.img_url != '' && img.img_url != null" :src="'http://' + img.img_url" alt="">
                <img v-else :src="'http://' + img.endpoint + '/design/com/img_ex0.jpg'" alt="">
              </div>
              <div class="desc">
                <!-- <input type="file" accept="image/*;capture=camera" @change="image_submit(index + 1, img.no)"
                  name="imgfile" :id="'imgfile' + (index + 1)" style="display:none" />
                <label :for="'imgfile' + (index + 1)" class="btn size1"> 이미지업로드{{ index + 1 }} </label> -->

                <!-- <a href="#delete" @click="img_delete(img.no)" class="btn size1">삭제</a> -->
              </div>
            </li>

            <li v-for="index in (img_max - img_list.length)" :key="index">
              <p class="image_desc">{{ image_std[img_list.length + index - 1] }}</p>
              <div class="img">
                <img :src="'http://' + endpoint + '/design/com/img_ex0.jpg'" alt="">
              </div>
              <div class="desc">
                <!-- <input type="file" accept="image/*;capture=camera" @change="image_submit(img_list.length + index)"
                  name="imgfile" :id="'imgfile' + (img_list.length + index)" style="display:none" />
                <label :for="'imgfile' + (img_list.length + index)" class="btn size1"> 이미지업로드{{ img_list.length + index
                }}</label> -->

                <!-- <a href="#delete" @click="img_delete()" class="btn size1">삭제</a> -->
              </div>
            </li>

          </ul>

          <div>
            <h3 class="mgT50 h_title">반려사유</h3>
            <textarea id="rr" name="rr" class="textareabox" v-model="poi_info.reject_reason"></textarea>
          </div>
        </div>
        <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
          <a @click="reject_toggle()" class="btn bg0">오류체크</a>
          <a v-if="poi_info.dstate == 2" @click="submit(3)" class="btn bg2">검수승인</a>
          <a v-if="poi_info.dstate == 2" @click="submit(-1)" class="btn bg3">반려</a>
        </div>
      </div>
      <!-- //contents -->
    </div>
    <Image_Reject :reject_flag="reject_flag" @close="reject_toggle" @reload="img_load" ref="rejectPop" />
  </div>
</template>

<script>
import axios from "axios";
import common from "../common/common.js";
import config from "../common/config.js";
import Header from "../components/Header.vue";
// import EXIF from "exif-js";
import ExifReader from 'exifreader';
import Image_Reject from "../components/Image_Reject.vue";


// var EXIF = require('exif-js');

export default {
  name: "ImgEditConfirmView",
  components: {
    Header,
    Image_Reject
  },
  data() {
    return {
      img_max: 5,
      endpoint: "kvqa.crowdbank.co.kr",
      poi_info: {},
      img_list: [],
      image_std: [],
      dummy_count: 0,
      reject_flag: false,
      ino: this.$route.params.img_no
    }
  },
  methods: {
    reject_toggle() { // 오류체크 팝업 토글
      this.reject_flag = !this.reject_flag;
      if (this.reject_flag) {
        this.$refs.rejectPop.load_reject(this.ino);
      }
    },
    img_load() { // 이미지 정보 및 poi 정보 불러오기
      axios({
        url: config.apiurl + "?part=image&mode=image_edit_confirm_view",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          ino: this.$route.params.img_no
        },
      }).then((response) => {
        let d = response.data;
        console.log(d)
        this.img_list = d.image_list;
        this.poi_info = d.poi_info;
        this.image_std = d.image_std;
        this.dummy_count = d.dummy_count;
      }).catch(function (error) {
        alert("오류가 발생했습니다...(1)");
        console.log("에러야!");
        console.log(error);
      });
    },
    get_time(expireddate) { // 잔여시간
      return common.remained_time(expireddate);
    },
    get_exif(img) { // 사진 gps정보 받기
      return new Promise((resolv) => {
        (async () => {
          try {
            const tags = await ExifReader.load(img);
            console.log(tags['Image Height'].value);
            console.log(tags['Image Width'].value);
            if (!Object.prototype.hasOwnProperty.call(tags, 'GPSLatitude') && !Object.prototype.hasOwnProperty.call(tags, 'GPSLatitude')) {
              resolv({ lat: 0, lng: 0, height: tags['Image Height'].value, width: tags['Image Width'].value })
            } else {
              resolv({
                lat: tags.GPSLatitude.description, lng: tags.GPSLongitude.description,
                height: tags['Image Height'].value, width: tags['Image Width'].value
              })
            }

          } catch (e) {
            console.log(e);
            resolv({ lat: 0, lng: 0, height: 0, width: 0 })

          }
        })();
      });
    },
    image_submit(num, itemno = 0) { // 이미지업로드
      var file = document.querySelector('#imgfile' + num);

      var reader = new FileReader();
      let base64conv = "";
      reader.readAsDataURL(file.files[0]);
      reader.onload = async () => {
        var img = new Image();
        img.src = reader.result;

        let img_info = await this.get_exif(img.src); // 사진 gps 및 해상도 정보 받기

        if (img_info.width < 3840 || img_info.height < 2160) {
          alert("4k 이상의 이미지만 업로드할 수 있습니다.");
        } else {
          base64conv = reader.result;

          var filePath = file.value;
          var filePathSplit = filePath.split('\\');
          var fileName = filePathSplit.slice(-1)[0];

          this.image_uplaod(fileName, base64conv, { lat: img_info['lat'], lng: img_info['lng'] }, itemno);
        }
      }
    },
    image_uplaod(filename, base, latlng, itemno = 0) { // 이미지 서버 업로드
      axios({
        url: config.apiurl + "?part=image&mode=image_upload",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          ino: this.$route.params.img_no,
          filename: filename,
          base: base,
          endpoint: this.endpoint,
          itemno: itemno,
          latlng: latlng,
        },
      }).then((response) => {
        console.log(response)
        this.img_load();
      }).catch(function (error) {
        alert("오류가 발생했습니다...(2)");
        console.log("에러야!");
        console.log(error);
      });
    },
    img_delete(itemno) { // 이미지 삭제
      if (confirm("해당 이미지를 삭제하시겠습니까?")) {
        axios({
          url: config.apiurl + "?part=image&mode=image_delete",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            itemno: itemno
          },
        }).then((response) => {
          response
          // console.log(response);
          this.img_load();
        }).catch(function (error) {
          alert("오류가 발생했습니다...(3)");
          console.log("에러야!");
          console.log(error);
        });
      }
    },
    submit(state) { // 검수처리
      let m = "해당 이미지 수정 작업을 검수 승인하시겠습니까?";
      if (state == -1) {
        m = "해당 이미지 수정 작업을 반려하시겠습니까?";
      }
      if (confirm(m)) {
        axios({
          url: config.apiurl + "?part=image&mode=image_edit_confirm_state",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            ino: this.$route.params.img_no,
            state: state,
            rr: this.poi_info.reject_reason,
          },
        }).then((response) => {
          console.log(response.data);
          if (state == 3) {
            alert("검수 승인 처리되었습니다.");
          } else if (state == -1) {
            alert("반려 처리되었습니다.");
          }
          this.$router.push({ name: "ImgEditConfirmList" });
        }).catch(function (error) {
          alert("오류가 발생했습니다...(4)");
          console.log("에러야!");
          console.log(error);
        });
      }
    },
    error_check(state) {
      console.log(state);
      let check = false;

      if (state == 1) {
        if (confirm("오류체크 처리하시겠습니까?")) {
          check = true;
        }
      } else if (state == 0) {
        if (confirm("오류체크를 해제하시겠습니까?")) {
          check = true;
        }
      }

      if (check) {
        axios({
          url: config.apiurl + "?part=image&mode=image_edit_confirm_set_reject",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            ino: this.$route.params.img_no,
            state: state,
          },
        }).then((response) => {
          console.log(response.data);
          if (state == 1) {
            alert("오류체크 처리되었습니다.");
          } else if (state == 0) {
            alert("오류체크가 해제되었습니다.");
          }
          this.img_load();
          // this.$router.push({ name: "ImgEditConfirmList" });
        }).catch(function (error) {
          alert("오류가 발생했습니다...(4)");
          console.log("에러야!");
          console.log(error);
        });
      }
    }
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 26);
    this.img_load();
  }
};
</script>